@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Ambit';
  font-weight: 400;
  src:
    local('Ambit'),
    url(./assets/fonts/Ambit/Ambit-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Ambit';
  font-weight: 400;
  font-style: italic;
  src:
    local('Ambit'),
    url(./assets/fonts/Ambit/Ambit-Italic.otf) format('opentype');
}
@font-face {
  font-family: 'Ambit';
  font-weight: 700;
  src:
    local('Ambit'),
    url(./assets/fonts/Ambit/Ambit-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Ambit';
  font-weight: 600;
  src:
    local('Ambit'),
    url(./assets/fonts/Ambit/Ambit-SemiBold.otf) format('opentype');
}
@font-face {
  font-family: 'Ambit';
  font-weight: 600;
  font-style: italic;
  src:
    local('Ambit'),
    url(./assets/fonts/Ambit/Ambit-SemiBoldItalic.otf) format('opentype');
}
